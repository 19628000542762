/* eslint-disable max-len */

class Config {
    /*
        these are the inuit breakpoints
        in order to use them you need a tool that can check if a mediaquery is active

        for example verge (bower install verge --save):

        if(verge.mq(Config.breakpoints.palm)){
            console.log("YES! Palm is active.");
        }
    */
    static init() {
        this.config = {
            debug: true,
            baseline: 30,
            breakpoints : {
                palm        : 'screen and (max-width: 44.9375em)',
                lap         : 'screen and (min-width: 45em) and (max-width: 63.9375em)',
                lapAndUp    : 'screen and (min-width: 45em)',
                portable    : 'screen and (max-width: 63.9375em)',
                desk        : 'screen and (min-width: 64em)',
                deskWide    : 'screen and (min-width: 95em)',
                retina      : '(-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi), (min-resolution: 2dppx)'
            },
            tabPrefix: 'paqt_tab_'
        };
    }
}

export default Config
