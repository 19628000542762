import $ from 'jquery';
import Helpers from './helpers';

class Documents {

    static init() {
        Documents.check();
    };

    static check() {
        if ($('.js-document-type').length > 0) {
            Documents.attach();
        }
    };

    static attach() {
        $('.js-document-type').off('change');
        $('.js-document-type').on('change', Helpers.submitParentForm);
        $('.js-document-status').off('change');
        $('.js-document-status').on('change', Helpers.submitParentForm);
        $('.js-document-file').off('change');
        $('.js-document-file').on('change', Helpers.submitParentForm);
    };
}

export default Documents
