import "jquery/src/jquery";
import $ from "jquery";

import "bootstrap-sass/assets/javascripts/bootstrap.min";
import Components from "./scripts/components";
import Config from "./scripts/config";
import Contracts from "./scripts/contracts";
import ContractEvents from "./scripts/contractevents";
import DataTables from "./scripts/datatables";
import Documents from "./scripts/documents";
import Editor from "./scripts/editor";
import Helpers from "./scripts/helpers";
import Tabs from "./scripts/tabs";
import Users from "./scripts/users";
import W2Form from "way2web-form";

require("./styles/main.scss");

/**
 *  Bootstrap the application when the jQuery ready event is fired.
 */
class Paqt {
  /**
   * Call function if the dom is ready.
   */
  static onDomReady = function() {
    const token = $('meta[name="csrf-token"]').attr("content");

    $.ajaxSetup({
      headers: {
        "X-CSRF-TOKEN": token
      }
    });

    new Tabs(); // eslint-disable-line no-new
    new DataTables(); // eslint-disable-line no-new

    Config.init();
    Editor.init();

    Components.initTypeChange();
    Components.initContractNotes();
    Components.initCompanyChange();

    $(".js-select2").each(Components.initSelect2);
    $(".js-datepicker").each(Components.initDatePicker);
    $(".datetimepicker").each(Components.initDateTimePicker);
    $(".js-confirm").each(Components.initConfirm);

    const form = new W2Form();
    form.init(document);

    Contracts.init();
    Documents.init();
    ContractEvents.init();
    Users.init();
  };
}

$(document).ready(Paqt.onDomReady);
