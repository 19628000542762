import $ from 'jquery';

class Contracts {

    static init() {
        Contracts.rentOnlyFields();
    };

    static rentOnlyFields() {
        var premiseField = $('.js-premise');

        premiseField.on('change', Contracts.checkForPremiseType);
        premiseField.change();
    };

    static checkForPremiseType() {
        var premiseId = $(this).val();
        var ajaxUrl = $(this).data('href');

        if (premiseId) {
            Contracts.getPremiseType(premiseId, ajaxUrl);
        }
    };

    static getPremiseType(premiseId, url) {
        $.ajax({
            url: url,
            data: {
                'premise_id': premiseId
            },
            success: function(response) {
                Contracts.hideOrShowByPremiseType(response);
            },
            dataType: 'json'
        });
    };

    static hideOrShowByPremiseType(premiseType) {
        var rentOnlyFields = $('.js-rent-only');

        if (premiseType == 'rent') {
            rentOnlyFields.show();

            return;
        }

        rentOnlyFields.hide();
    };
}

export default Contracts
