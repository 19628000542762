import $ from 'jquery';
import Helpers from './helpers';

class ContractEvents {

    static init() {
        this.check();
    };

    static check() {
        if ($('.js-event').length > 0) {
            this.attach();
        }
    };

    static attach() {
        $('.js-event').off('change');
        $('.js-event').on('change', Helpers.submitParentForm);
        $('.js-event-date').off('change');
        $('.js-event-date').on('change', Helpers.submitParentForm);
    };
}

export default ContractEvents
