import $ from 'jquery';

class Helpers {

    // Returns a function, that, as long as it continues to be invoked, will not
    // be triggered. The function will be called after it stops being called for
    // N milliseconds. If `immediate` is passed, trigger the function on the
    // leading edge, instead of the trailing.
    //
    // source: http://snippetrepo.com/snippets/basic-vanilla-javascript-throttlingdebounce
    static debounce(func, wait, immediate) {
        var timeout;

        return function() {
            var context = this;
            var args = arguments;

            clearTimeout(timeout);
            timeout = setTimeout(function() {
                timeout = null;
                if (!immediate) {
                    func.apply(context, args);
                }
            }, wait);
            if (immediate && !timeout) {
                func.apply(context, args);
            }
        };
    };

    static getLanguage() {
        var language = $('.js-language').attr('lang');

        if (typeof language === 'undefined' || language === '') {
            throw 'language code missing';
        }

        return language;
    };

    static submitParentForm() {
        if (!$(this).val) {
            return;
        }

        $(this).closest('form').submit();
    };
}

export default Helpers
