import BootstrapTabs from 'way2web-bootstrap-tabs';

/**
 * With this function you can add more functions to the tabs.
 * Next time you visit the page, the last active tab will be active again.
 * On a page with a form and multiple tabs, the last active tab will open again on a form submit..
 * When it detects an error in a tab, it opens that tab, so you see the error.
 *
 * You can also open a tab outside with e.g.:
 * Tabs.items.orderform.open('#payment')
 *
 * You can get the active tab by:
 * Tabs.items.orderform.active()
 *
 * You can find how many errors there are with:
 * Tabs.items.orderform.errors()
 */

class Tabs {
    /**
     * Initialize the tabs component.
     */
    constructor() {
        // Array with the elements for the tabs.
        this.elements = {
            tabNav: '.js-tabs'
        };

        // The tabs will be stored in this object.
        this.items = {};

        $(this.elements.tabNav).each(this.find.bind(this));
    }

    /**
     * Attach to the Bootstrap tabs the function to switch to the active on reload, or go to the tab with errors.
     *
     * @param {integer} index
     * @param {object} element
     */
    find(index, element) {
        let tabPrefix = (typeof config !== 'undefined' && config.tabPrefix)
            ? config.tabPrefix
            : null;

        let item = new BootstrapTabs($(element), tabPrefix);

        if (!item) {
            return;
        }

        item.listen();
        this.items[item.id] = item;
    }
}

export default Tabs
