import 'summernote/dist/summernote';
import W2Summernote from 'way2web-summernote/js/summernote.core';

class Editor {
    /**
     * Initialize the editor component.
     */
    constructor() {
        this.summernote = [];
        this.toolbar = [
            ['style', ['bold', 'italic', 'underline', 'clear']],
            ['font', ['strikethrough', 'superscript', 'subscript']],
            ['para', ['ul', 'ol', 'paragraph']],
            ['insert', ['link']],
            ['misc', ['undo', 'redo']],
            ['codeview', ['codeview']],
        ];
    }

    // Find the elements to connect the editor.
    static init() {
        const editor = new Editor();

        $('.js-wysiwyg').each(editor.initElement.bind(editor));
    }

    /**
     * Attach summernote to the textareas.
     *
     * @param {integer} index
     * @param {object} element
     */
    initElement(index, element) {
        this.summernote.push(
            new W2Summernote()
                .element($(element))
                .toolbar(this.toolbar)
                .tooltip(false)
                .init()
        );
    }
}

export default Editor;
