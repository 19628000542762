import $ from 'jquery';

class Users {

    static init() {
        $('.js-role').on('change', Users.checkRole);

        if ($('.js-role').val()) {
            $('.js-role').trigger('change');
        }
    };

    static hideLogo() {
        $('.js-logo').find('input').attr('disabled', true);
        $('.js-logo').hide();
    };

    static showLogo() {
        $('.js-logo').find('input').attr('disabled', false);
        $('.js-logo').show();
    };

    static checkRole() {
        var roleElement = $(this);
        var companyIdElement = $('.js-company');

        if (roleElement.val() == 'user-manager') {
            Users.showLogo();
            $('.js-company-name').show();
        } else {
            Users.hideLogo();
            $('.js-company-name').hide();
        }

        if (roleElement.val() == 'client') {
            companyIdElement.show();
            companyIdElement.find('select').removeAttr('disabled');
        } else {
            companyIdElement.hide();
            companyIdElement.find('select').attr('disabled', 'disabled');
        }
    };
}

export default Users
