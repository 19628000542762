import $ from 'jquery';
import Pikaday from 'pikaday/pikaday'
import 'select2/dist/js/select2';
import 'way2web.jquery.confirm/js/jquery.confirm';
/* eslint-disable max-len */

class Components {
    static initDateTimePicker() {
        const element = $(this);

        const options = {
            field: $(this)[0],
            daysOfWeekDisabled: [0, 6],
            format: element.data('date-format'),//format: 'YYYY-MM-DD HH:mm:ss',
            sideBySide: true,
            useCurrent: false
        }

        /**
         * Pikaday is a vanilla JS package, so use the vanilla JS dom element
         * from the jQuery element object.
         */
        new Pikaday(options); // eslint-disable-line no-new
    };

    /**
     * Initialize the date picker.
     */
     static initDatePicker() {
        const element = $(this);

        const options = {
            field: element[0],
            format: element.data('date-format'),
        }

        /**
         * Pikaday is a vanilla JS package, so use the vanilla JS dom element
         * from the jQuery element object.
         */
        new Pikaday(options); // eslint-disable-line no-new
    };

    /**
     * Initialize the confirm function.
     */
     static initConfirm() {
        const confirmElement = $(this);

        // somehow $.fn.confirm is not set correctly
        if ($.fn.confirm == null) {
            $.fn.confirm = jQuery.fn.confirm;
        }

        confirmElement.confirm({
            title: 'Confirmation required'
        });
    };

    /**
     * Initialize the select2 function, for nice select elements.
     */
     static initSelect2() {
        var select = $(this);
        var placeholder = select.data('placeholder');
        var dataUrl = select.data('dataUrl');
        var dataType = select.data('dataType');
        var options = {
            allowClear:  true,
            placeholder: placeholder,
            theme:       'bootstrap',
            width:       null
        };

        if (typeof placeholder === 'undefined' || placeholder === '') {
            throw 'A data-placeholder value is needed on the select2 element';
        }

        if (dataUrl) {
            options.ajax = {
                url:      dataUrl,
                dataType: dataType,
                delay:    250,
                cache:    true,
                data:     function(params) {
                    return {
                        search: params.term
                    };
                }
            };
            options.minimumInputLength = 2;
        }

        select.select2(options);
    };

    static initTypeChange() {
        if ($('.js-type').val() == 'rent') {
            $('.js-property').hide();
        }
        if ($('.js-type').val() == 'property') {
            $('.js-rent').hide();
        }
        $('.js-type').change(function() {
            if ($(this).val() == 'rent') {
                $('.js-property').hide();
                $('.js-rent').show();
            }
            if ($(this).val() == 'property') {
                $('.js-rent').hide();
                $('.js-property').show();
            }
        });
    };

    static initCompanyChange() {
        var premiseSelect = $('.js-premise');
        var premiseSet = premiseSelect.data('premise-set');

        if (!premiseSelect.length) {
            return;
        }

        if (!premiseSet) {
            Components.getPremises($('.js-company').val());
        }

        $('.js-company').change(function() {
            Components.getPremises($(this).val());
        });
    };

    static loadPremises(data) {
        var select = $('.js-premise');
        var i = 0;

        select.empty();
        for (i = 0; i < data.length; i++) {
            select.append('<option value="' + data[i].id + '">' + data[i].name + '</option>');
        }

        select.trigger('change');
    };

    static getPremises(company) {
        if (company) {
            $('.js-premise').empty();

            $.ajax({
                url: '/company/' + company + '/ajax-get-premises',
                success: Components.loadPremises,
                dataType: 'json'
            });
        }
    };

    static initContractNotes() {
        $('.js-notes').change(function() {
            var notes = $(this).val();

            $('.js-notes-hidden').val(notes);
        });
    };
}

export default Components
