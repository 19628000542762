import $ from "jquery";
import DataTable from "way2web-datatables/dist/datatables";
/* eslint-disable max-len */

/**
 * Add datatables to the page with ajax.
 */
class DataTables {
  /**
   * Initialize the datatables component.
   */
  constructor() {
    this.items = [];
    this.elements = {
      tableSelector: ".js-datatable"
    };

    $(this.elements.tableSelector).each(this.find.bind(this));
  }

  /**
   * Attach the DataTable core to the elements.
   *
   * @param {integer} index
   * @param {object} element
   */
  find(index, element) {
    const item = new DataTable(
      $(element),
      {
        language: "en",
        order: [],
        dom:
          '<"row"<"col-md-4"f><"col-md-4 col-md-offset-4 text-right"B>>trlip<"clear">'
      },
      {},
      this.translations()
    );

    item.init();

    this.items.push(item);

    $(item.table).on("draw.dt", () => {
      this.clickableRows();
    });

    window.addEventListener('resize', () => {
      this.clickableRows();
    });
  }

  /**
   * Initiate the clickable rows, but only on lap and up. To prevent issues with collapsed rows.
   */
  clickableRows() {
    // if (!verge.mq("screen and (min-width: 45em)")) {
    //   return;
    // }

    this.clickableStyling();
    this.clickableFunctionality();
  }

  /**
   * Add hover classes to table and rows and hide the last column.
   */
  clickableStyling() {
    $(".js-clickable-rows").each(function() {
      const table = $(this);

      table.addClass("table-hover");

      table.find("thead tr th:last-child").hide();
      table.find("tbody tr td:last-child").hide();
      table.find("tfoot tr th:last-child").hide();

      table
        .find("tbody")
        .find("tr")
        .addClass("table-clickable-row");
    });
  }

  /**
   * Makes the table rows clickable based on the edit button.
   */
  clickableFunctionality() {
    $(".js-clickable-rows").on("click", "td", function() {
      var tdElement = $(this);
      var tableElement = tdElement.closest("table");
      var href = tdElement
        .closest("tr")
        .find(".js-edit-button")
        .attr("href");
      var tdHasCollapseButton =
        tableElement.hasClass("collapsed") && tdElement.is(":first-child");

      if (!tdHasCollapseButton) {
        window.location.href = href;
      }
    });
  }

  /**
   * The translations object for the Datatables package.
   *
   * @return {object}
   */
  translations() {
    return {
      /**
       * Get all the datatables translations with Lang.js.
       *
       * @return {object}
       */
      get() {
        return {
          oAria: {
            sSortAscending: ": activate to sort column ascending",
            sSortDescending: ": activate to sort column descending"
          },
          oPaginate: {
            sFirst: "First",
            sLast: "Last",
            sNext: "Next",
            sPrevious: "Previous"
          },
          sEmptyTable: "No data available in table",
          sInfo: "Showing _START_ to _END_ of _TOTAL_ entries",
          sInfoEmpty: "Showing 0 to 0 of 0 entries",
          sInfoFiltered: "(filtered from _MAX_ total entries)",
          sInfoPostFix: "",
          sInfoThousands: ",",
          sLengthMenu: "Show _MENU_ entries",
          sLoadingRecords: "Loading...",
          sProcessing: "Processing...",
          sSearch: "",
          sZeroRecords: "No matching records found",
          all: "All",
          columns: "Columns"
        };
      }
    };
  }
}

export default DataTables;
